import React, { useState } from "react"
import styled from "styled-components"
import { Link, useStaticQuery, graphql } from "gatsby"
import FormButton from "../components/form-button"
import CTA from "../components/cta"
import ResponsiveSpacing from "../components/responsive-spacing"
import { connect } from "react-redux"
import Status from "../components/status"
import Layout from "../components/layout"
import Inner from "../components/inner"
import SEO from "../components/seo"
import { Row, Col } from "../components/rows"
import FeaturedMerch from "../components/featured-merch"
import Feature from "../components/feature"
import { createCartAction } from "../reducers/cart"

const CartItemStyled = styled.div`
  margin: 0 auto;
  .item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    padding: 30px 0;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  .left-side {
    display: grid;
    grid-template-columns: 164px 1fr;
    grid-gap: 16px;

    @media (max-width: 768px) {
      padding: 0;
      grid-template-columns: 1fr 1fr;
    }
  }

  .image {
    background-color: ${(p) => p.theme.LightBlue};
    width: 164px;
    height: 164px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    max-width: 164px;
  }

  .info {
    color: ${(p) => p.theme.DarkGrey};
    font-size: 1em;
  }

  h3 {
    color: ${(p) => p.theme.DarkBlue};
    font-family: "interstate-compressed";
    margin: -6px 0 2px 0;
    text-transform: uppercase;
  }

  .sub-head {
    margin: 0;
    text-transform: uppercase;
    line-height: 1em;
    color: ${(p) => p.theme.Blue};
  }

  .price {
    color: ${(p) => p.theme.DarkBlue};
    text-align: right;
  }

  .remove {
    margin-top: 5px;
    display: block;
    font-size: 0.8em;
    text-transform: uppercase;
    color: ${(p) => p.theme.Blue};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .action {
    font-family: "interstate-compressed";
  }

  ul {
    list-style: none;
    padding: 0;
    line-height: 1.3em;
    margin: 5px 0;
    text-transform: uppercase;
  }
`

const CartItem = ({ product, index, dispatch }) => {
  const item = product
  const price = product.price
  const image = item.image
  const title = product.name
  const sub_title = ""
  let lineItems = product.lineItems
  if (product.pints) lineItems = product.pints.map((p) => p.name)
  let lineItemsHTML = ""
  if (lineItems) {
    const itemList = (
      <ul>
        {lineItems.map((i, ind) => {
          return <li key={ind}>{i}</li>
        })}
      </ul>
    )
    lineItemsHTML = itemList
  }
  return (
    <CartItemStyled>
      <div className="item">
        <div className="left-side">
          <div className="image">
            <img alt={title} src={image} />
          </div>
          <div className="info">
            <h3>{title}</h3>
            <p className="sub-head">{sub_title}</p>
            {lineItemsHTML}
          </div>
        </div>
        <div>
          <div className="right-side">
            <div className="price">${price}</div>
            <div>
              <a
                className="remove"
                onClick={(e) => {
                  e.preventDefault()
                  dispatch({ type: "REMOVE_FROM_CART", removeIndex: index })
                }}
                href="#"
              >
                REMOVE
              </a>
            </div>
          </div>
        </div>
      </div>
    </CartItemStyled>
  )
}

const CartPageStyled = styled.div`
  max-width: 1400px;
  margin: 123px auto 150px auto;

  @media (max-width: 1400px) {
  }

  @media (max-width: 768px) {
  }
`

const CartStyled = styled.div`
  padding: 40px 0;
  @media (max-width: 768px) {
    padding: 60px 0;
  }

  .table-heading {
    max-width: 1400px;
    margin: 0 auto;
    justify-content: flex-end;
    display: flex;
  }

  .right-headings {
    padding: 16px 0px 4px 0;
    width: 80px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    text-align: center;
    color: ${(p) => p.theme.DarkBlue};

    h4 {
      font-size: 2em;
      margin: 0;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  .right-side {
    float: right;
    font-size: 1.8em;
    font-weight: bold;
    padding: 0;
    text-align: right;
    color: ${(p) => p.theme.DarkBlue};

    @media (max-width: 768px) {
      padding: 16px 0;
      width: 100%;
    }
  }

  .noItems {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    color: ${(p) => p.theme.Blue};
  }

  .clear {
    clear: both;
    content: "";
    display: block;
  }
`

const TableStyled = styled.div`
  border-top: 2px solid ${(p) => p.theme.Grey};
  border-bottom: 2px solid ${(p) => p.theme.Grey};
  max-width: 1400px;
  min-height: 300px;
`

const CartHeading = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin: 0 auto;

  h1 {
    font-family: "interstate-compressed";
    color: ${(p) => p.theme.DarkBlue};
    font-size: 4em;
    margin: 0;
    letter-spacing: 2px;
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    a {
      text-decoration: none;
      color: ${(p) => p.theme.DarkBlue};
    }
    @media (min-width: 769px) {
      &:hover {
        text-decoration: normal;
      }
    }
  }
`

const Table = ({ children }) => {
  return <TableStyled>{children}</TableStyled>
}

const TotalsStyled = styled.div`
  max-width: 200px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: right;
  float: right;
  margin-right: 0;
  padding-top: 20px;

  p {
    font-size: 1.8em;
    font-weight: bold;
    color: ${(p) => p.theme.DarkBlue};
  }

  @media (max-width: 768px) {
    float: none;
    width: auto;
  }

  p.subTotal {
    text-transform: uppercase;
  }
`

const Totals = ({ fields, total, shipping }) => {
  return (
    <TotalsStyled>
      <p className="subTotal">{fields.cart_info.subtotal_wording}</p>
      <p className="number">${total}</p>
    </TotalsStyled>
  )
}

const CheckoutButtonsStyled = styled.div`
  width: 200px;
  grid-gap: 32px;
  float: right;

  @media (max-width: 768px) {
    padding: 20px 0;
    width: auto;
    float: none;
    grid-gap: 4px;
  }
`
// <Button>{fields.cart_info.update_cart_button_label}</Button>

const CheckoutButtons = ({ fields, cart, dispatch, setStatus }) => {
  const { items } = cart
  const [loading, setLoading] = useState(false)
  return (
    <CheckoutButtonsStyled>
      <FormButton
        loading={loading}
        onClick={(e) => {
          if (items.length === 0) return
          setLoading(true)
          dispatch(createCartAction()).then((json) => {
            if (json.errors) {
              console.log("ERROR", json.errors)
              setStatus(json.errors[0].message)
              return
            }
            if (json.data.checkoutCreate) {
              dispatch({ type: "CLEAR_CART_BACKUP" })
              setStatus("")
              setLoading(false)
              window.location.href = json.data.checkoutCreate.checkout.webUrl
            }
          })
        }}
      >
        {loading ? "PROCESSING" : fields.cart_info.checkout_button_label}
      </FormButton>
    </CheckoutButtonsStyled>
  )
}

function round(value, decimals) {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals).toFixed(2)
}

const Cart = ({ cart, merch, fields, dispatch }) => {
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState("")
  const { items, hideUpsell } = cart
  const cartItems = items.map((it, i) => {
    return <CartItem dispatch={dispatch} index={i} key={i} product={it} />
  })
  let total = items.reduce((a, c) => {
    const p = parseFloat(c.price)
    return p + a
  }, 0)
  const upsellIds = fields.upsell_merch.map((m) => m.wordpress_id)
  const featured = merch.filter((n) => upsellIds.includes(n.wordpress_id))[0]
    .acf
  total = round(total, 2)

  const alreadyGotIt = items.map((i) => i.sku).includes(featured.sku)
  const noItemsHTML =
    items.length === 0 ? (
      <div className="noItems">{fields.cart_info.empty_cart_wording}</div>
    ) : (
      ""
    )
  const hide = hideUpsell || alreadyGotIt || featured.promotion === "sold-out"
  return (
    <CartStyled>
      <ResponsiveSpacing>
        <CartHeading>
          <h1>{fields.cart_info.long_heading}</h1>
          <div className="right">
            <Link to="/flavors/">
              <CTA>{fields.cart_info.continue_shopping_link}</CTA>
            </Link>
          </div>
          <Status
            status={status}
            className="float-right"
            setStatus={setStatus}
          />
        </CartHeading>
        <div className="table-heading">
          <div className="right-headings">
            <div>&nbsp;</div>
            <div>
              <h4>{fields.cart_info.price_heading}</h4>
            </div>
          </div>
        </div>
        <Table>
          <div>
            {cartItems}
            {noItemsHTML}
          </div>
        </Table>
        <Inner maxWidth="1400px" height={"200px"}>
          <Row margin="50px 0" width="1400px">
            <Col>
              <FeaturedMerch
                dispatch={dispatch}
                product={featured}
                hide={hide}
              />
            </Col>
            <Col>
              <Totals fields={fields} total={total} />
              <div className="clear" />
              <CheckoutButtons
                fields={fields}
                dispatch={dispatch}
                cart={cart}
                setStatus={setStatus}
              />
            </Col>
          </Row>
        </Inner>
        <Feature {...fields.shipping_info} align="right" />
      </ResponsiveSpacing>
    </CartStyled>
  )
}

const ConnectedCart = connect((state) => state)(Cart)

const CartPage = () => {
  const data = useStaticQuery(graphql`
    query {
      merch: allWordpressWpMerch {
        edges {
          node {
            id
            wordpress_id
            title
            acf {
              name
              sku
              price
              promotion
              short_info
              images {
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 155, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      page: allWordpressPage(filter: { slug: { eq: "cart-page" } }) {
        edges {
          node {
            acf {
              cart_info {
                long_heading
                checkout_button_label
                continue_shopping_link
                price_heading
                subtotal_wording
                empty_cart_wording
                add_to_cart_button_label
              }
              shipping_info {
                headline
                sub_heading
                summary
                button_label
                button_link
                wave_color
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 450, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                flat_rate
              }
              upsell_merch {
                wordpress_id
              }
              search_title
              search_description
              share_headline
              share_comment
              share_image {
                source_url
              }
              cyo_packs {
                pack {
                  name
                  sub_title
                  description
                  productType
                  number_pints
                  image {
                    localFile {
                      childImageSharp {
                        fixed(width: 300, quality: 100) {
                          src
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const fields = data.page.edges[0].node.acf
  const merch = data.merch.edges.map((e) => e.node)
  const {
    search_title,
    share_headline,
    search_description,
    share_comment,
    share_image,
  } = fields

  return (
    <Layout>
      <SEO
        title={search_title}
        description={search_description}
        headline={share_headline}
        share_comment={share_comment}
        image={share_image}
      />
      <CartPageStyled>
        <ConnectedCart fields={fields} merch={merch} />
      </CartPageStyled>
    </Layout>
  )
}

export default CartPage
